<!-- eslint-disable @angular-eslint/template/use-track-by-function -->
<!-- eslint-disable @angular-eslint/template/prefer-control-flow -->
<!-- eslint-disable @angular-eslint/template/button-has-type -->
<!-- Course Categories Autocomplete Field -->
<div class="course-category-container course-category-box">
  <mat-form-field appearance="outline" i18n-appearance class="full-width">
    <input
      #categoryInput
      matInput
      placeholder="Search categories"
      i18n-placeholder
      [formControl]="categoryCtrl"
      [matAutocomplete]="autoCategory"
    />
    <button
      class="add-button"
      mat-icon-button
      matSuffix
      aria-label="Add category"
      i18n-aria-label
      (click)="addCategoryFromInput()"
    >
      <mat-icon i18n>add</mat-icon>
    </button>
    <mat-autocomplete
      #autoCategory="matAutocomplete"
      (optionSelected)="addCategory($event)"
    >
      <mat-option
        *ngFor="let category of filteredCategories | async"
        [value]="category"
      >
        {{ category }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
